export default {
  common: {
    add: '추가',
    addSuccess: '추가 성공',
    edit: '편집',
    editSuccess: '편집 성공',
    delete: '삭제',
    deleteSuccess: '삭제 성공',
    save: '저장',
    saveSuccess: '저장 성공',
    reset: '초기화',
    action: '액션',
    export: '내보내기',
    exportSuccess: '내보내기 성공',
    import: '가져오기',
    importSuccess: '가져오기 성공',
    clear: '비우기',
    clearSuccess: '비우기 성공',
    yes: '예',
    no: '아니오',
    confirm: '확인',
    download: '다운로드',
    noData: '데이터 없음',
    wrong: '문제가 발생했습니다. 나중에 다시 시도하십시오.',
    success: '성공',
    failed: '실패',
    verify: '검증',
    unauthorizedTips: '인증되지 않았습니다. 먼저 확인하십시오.',
  },
  chat: {
    newChatButton: '새로운 채팅',
    placeholder: '무엇이든 물어보세요...(Shift + Enter = 줄바꿈, "/"를 눌러서 힌트를 보세요)',
    placeholderMobile: '무엇이든 물어보세요...',
    copy: '복사',
    copied: '복사됨',
    copyCode: '코드 복사',
    clearChat: '채팅 비우기',
    clearChatConfirm: '이 채팅을 비우시겠습니까?',
    exportImage: '이미지 내보내기',
    exportImageConfirm: '이 채팅을 png로 내보내시겠습니까?',
    exportSuccess: '내보내기 성공',
    exportFailed: '내보내기 실패',
    usingContext: '컨텍스트 모드',
    turnOnContext: '현재 모드에서는 이전 대화 기록을 포함하여 메시지를 보낼 수 있습니다.',
    turnOffContext: '현재 모드에서는 이전 대화 기록을 포함하지 않고 메시지를 보낼 수 있습니다.',
    deleteMessage: '메시지 삭제',
    deleteMessageConfirm: '이 메시지를 삭제하시겠습니까?',
    deleteHistoryConfirm: '이 기록을 삭제하시겠습니까?',
    clearHistoryConfirm: '채팅 기록을 삭제하시겠습니까?',
    preview: '미리보기',
    showRawText: '원본 텍스트로 보기',
  },
  setting: {
    setting: '설정',
    general: '일반',
    advanced: '고급',
    config: '설정',
    avatarLink: '아바타 링크',
    name: '이름',
    description: '설명',
    role: '역할',
    temperature: '온도',
    top_p: 'Top_p',
    resetUserInfo: '사용자 정보 초기화',
    chatHistory: '채팅 기록',
    theme: '테마',
    language: '언어',
    api: 'API',
    reverseProxy: '리버스 프록시',
    timeout: '타임아웃',
    socks: 'Socks',
    httpsProxy: 'HTTPS 프록시',
    balance: 'API 잔액',
    monthlyUsage: '월 사용량',
  },
  store: {
    siderButton: '프롬프트 저장소',
    local: '로컬',
    online: '온라인',
    title: '제목',
    description: '설명',
    clearStoreConfirm: '데이터를 삭제하시겠습니까?',
    importPlaceholder: '여기에 JSON 데이터를 붙여넣으십시오',
    addRepeatTitleTips: '제목 중복됨, 다시 입력하십시오',
    addRepeatContentTips: '내용 중복됨: {msg}, 다시 입력하십시오',
    editRepeatTitleTips: '제목 충돌, 수정하십시오',
    editRepeatContentTips: '내용 충돌 {msg} , 수정하십시오',
    importError: '키 값 불일치',
    importRepeatTitle: '제목이 반복되어 건너뜀: {msg}',
    importRepeatContent: '내용이 반복되어 건너뜀: {msg}',
    onlineImportWarning: '참고: JSON 파일 소스를 확인하십시오!',
  },
}
